import { getPrice, modificationsHasPrice, roundNumber } from "TempUtils";
import { getSingleModification } from "TempUtils/modsHelpers";
import { findLowestModificationPrice, modificationsHasAddonPrice } from "TempUtils/TextFormat";
import { getVatAmount } from "TempUtils/PriceCalculations";
import { calculateTotalAddonPrices, getCartProductOrgUnitPrice } from "../../shared/utils/cartController";
import { isNumber, keyBy, min, round, sum } from "lodash";
import { Languages, createSimpleHashId } from "Providers";
import { getPriceString } from "PriceUtils";
import { isVariablePriceType } from "ProductUtils";
import { DISCOUNT_TYPE } from "Types";

export const getBundleFromPrice = (menuBundleProduct, refProductHashMap = {}) => {
    if (!menuBundleProduct.price || menuBundleProduct.price === 0) {
        const {
            menuBundleModifications,
            refBundleProduct: { bundleProductCategories }
        } = menuBundleProduct;
        const menuOverriddenMods = keyBy(menuBundleModifications, "refProductId");
        const bundleMinPrice = sum(
            bundleProductCategories.map(cat => {
                const minAddonPrice = cat.refProductIdList.map(id => {
                    const refProd = refProductHashMap[id];
                    if (!refProd) return 0;
                    const modsToUse = menuOverriddenMods[id]
                        ? menuOverriddenMods[id].modifications
                        : refProd.modifications;
                    return modsToUse ? cat.limit * findLowestModificationPrice(modsToUse) : 0;
                });
                const filteredMinAddonPrice = menuBundleProduct.refBundleProduct.defaultPrice
                    ? minAddonPrice.filter(el => el > 0)
                    : [...minAddonPrice];
                return minAddonPrice ? min(filteredMinAddonPrice) : 0;
            })
        );
        return bundleMinPrice ? bundleMinPrice : 0;
    } else {
        return 0;
    }
};
export const hasBundleModificationPrice = (menuBundleProduct, refProductHashMap = {}) => {
    const {
        menuBundleModifications,
        refBundleProduct: { bundleProductCategories }
    } = menuBundleProduct;
    const menuOverriddenMods = keyBy(menuBundleModifications, "refProductId");
    return bundleProductCategories.some(product => {
        return product.refProductIdList.some(id => {
            const refProd = refProductHashMap[id];
            if (!refProd) return 0;
            const modsToUse = menuOverriddenMods[id] ? menuOverriddenMods[id].modifications : refProd.modifications;
            const hasModification = modsToUse
                ? modsToUse.sizes.some(size => size.price > 0 || size.addonPrice > 0) ||
                  modsToUse.flavours.some(flavour => flavour.price > 0 || flavour.addonPrice > 0)
                : false;
            return hasModification;
        });
    });
};

const getFromText = userLanguage => {
    if (userLanguage === Languages.SV) {
        return "Från";
    } else {
        return "From";
    }
};

export const swedishPriceText = value => value.toLocaleString("sv-SE", { style: "currency", currency: "SEK" });

/**
 * Calculates the price text
 * @param {object} product - Could either be a menuproduct or a menubundleproduct
 * @return {string} A text string with price info
 */
// WARNING: Don't use this for new code
/** @deprecated , use instead: import { getPriceStringForProduct } from "PriceUtils" */
export const getPriceText = (product, refProductHashMap = {}, userLanguage = "sv") => {
    const { refProduct } = product;
    return refProduct
        ? getMenuProductPriceText(product, userLanguage)
        : getMenuBundleProductPriceText(product, refProductHashMap, userLanguage);
};

/**
 * Passes in product and calculates price text and discount text (if it is there)
 * @param {object} product
 * @param {object} refProductHashMap
 * @param {object | null} rateOrAmount
 * @param {string} userLanguage
 * @returns {any} {price, discountPrice}
 */
export const getPriceAndDiscountText = (product, refProductHashMap = {}, rateAndAmount, userLanguage = "sv") => {
    const { refProduct } = product;
    return refProduct
        ? getMenuProductPriceAndDiscountText(product, rateAndAmount, userLanguage)
        : getMenuBundleProductPriceAndDiscountText(product, rateAndAmount, refProductHashMap, userLanguage);
};

// WARNING: Don't use this for new code, use instead:
/** @deprecated use instead: import { getPriceStringForMenuBundleProduct } from "PriceUtils"*/
const getMenuBundleProductPriceText = (menuBundleProduct, refProductHashMap, userLanguage) => {
    const priceType = menuBundleProduct.refBundleProduct.priceType;
    if (!menuBundleProduct.price && menuBundleProduct.refBundleProduct.defaultPrice === 0) {
        const bundleFromPrice = getBundleFromPrice(menuBundleProduct, refProductHashMap);
        const priceString = getPriceString(bundleFromPrice, priceType);
        return bundleFromPrice === 0 ? "" : `${getFromText(userLanguage)} ${priceString}`;
    }
    const menuPrice = menuBundleProduct.price || menuBundleProduct.refBundleProduct.defaultPrice;
    const priceString = getPriceString(menuPrice, priceType);
    return priceString;
};

const getMenuBundleProductPriceAndDiscountText = (menuBundleProduct, rateOrAmount, refProductHashMap, userLanguage) => {
    if (!menuBundleProduct.price) {
        const defaultPrice = menuBundleProduct.refBundleProduct.defaultPrice;
        const bundleFromPrice = getBundleFromPrice(menuBundleProduct, refProductHashMap);
        const price = roundNumber(defaultPrice ? defaultPrice : bundleFromPrice);
        const discountPrice = calculateFixedDiscountPrice(rateOrAmount, defaultPrice ? defaultPrice : bundleFromPrice);
        const isFree = discountPrice === 0 || price <= discountPrice;

        return {
            price: bundleFromPrice === 0 ? (price ? `${price} kr` : "") : `${getFromText(userLanguage)} ${price} kr`,
            discountPrice:
                bundleFromPrice === 0
                    ? defaultPrice
                        ? `${swedishPriceText(discountPrice)}`
                        : ""
                    : `${getFromText(userLanguage)} ${swedishPriceText(discountPrice)}`,
            isFree
        };
    }
    const price = roundNumber(menuBundleProduct.price || menuBundleProduct.refBundleProduct.defaultPrice);
    const discountPrice = calculateFixedDiscountPrice(
        rateOrAmount,
        menuBundleProduct.price || menuBundleProduct.refBundleProduct.defaultPrice
    );
    const isFree = discountPrice === 0 || price <= discountPrice;
    const getPrice = hasBundleModificationPrice(menuBundleProduct, refProductHashMap)
        ? `${getFromText(userLanguage)} ${price} kr`
        : `${price} kr`;
    return {
        price: getPrice,
        discountPrice: swedishPriceText(discountPrice),
        isFree
    };
};

// WARNING: Don't use this for new code, use instead:
/** @deprecated use instead: import { getPriceStringForMenuProduct } from "PriceUtils" */
const getMenuProductPriceText = (menuProduct, userLanguage) => {
    const modificationsToUse = menuProduct.modifications || menuProduct.refProduct.modifications;
    const modHasPrice = modificationsToUse && modificationsHasPrice(modificationsToUse);
    const modHasAddonPrice = modificationsToUse && modificationsHasAddonPrice(modificationsToUse);
    const isSingleMod = modificationsToUse && getSingleModification(modificationsToUse);
    const productBasePrice = menuProduct.price || menuProduct.refProduct.defaultPrice;
    const priceType = menuProduct.refProduct.priceType;

    if (modHasPrice && !!isSingleMod) {
        const price = findLowestModificationPrice(modificationsToUse);
        return getPriceString(price, priceType);
    } else if ((modHasPrice || modHasAddonPrice) && !!!isSingleMod) {
        const lowestPrice = (modHasPrice ? 0 : productBasePrice) + findLowestModificationPrice(modificationsToUse);
        const priceString = getPriceString(lowestPrice, priceType);
        return lowestPrice === 0 ? "" : `${getFromText(userLanguage)} ${priceString}`;
    } else {
        const addonPrice = !!isSingleMod ? getSelectedModificationAddonPrice(isSingleMod) : 0;
        return getPriceString(productBasePrice + addonPrice, priceType);
    }
};

const getMenuProductPriceAndDiscountText = (menuProduct, rateOrAmount, userLanguage) => {
    const modificationsToUse = menuProduct.modifications || menuProduct.refProduct.modifications;
    const modHasPrice = modificationsToUse && modificationsHasPrice(modificationsToUse);
    const modHasAddonPrice = modificationsToUse && modificationsHasAddonPrice(modificationsToUse);
    const isSingleMod = modificationsToUse && getSingleModification(modificationsToUse);
    const productBasePrice = menuProduct.price || menuProduct.refProduct.defaultPrice;

    if (modHasPrice && !!isSingleMod) {
        const price = findLowestModificationPrice(modificationsToUse);
        const discountPrice = calculateFixedDiscountPrice(rateOrAmount, price);
        const isFree = discountPrice === 0 || discountPrice <= price;
        return {
            price: `${roundNumber(price)} kr`,
            discountPrice: !!rateOrAmount ? swedishPriceText(isFree) : "",
            isFree
        };
    } else if ((modHasPrice || modHasAddonPrice) && !!!isSingleMod) {
        const lowestPrice = (modHasPrice ? 0 : productBasePrice) + findLowestModificationPrice(modificationsToUse);
        const discountPrice = calculateFixedDiscountPrice(rateOrAmount, lowestPrice);
        const isFree = discountPrice === 0 || lowestPrice <= discountPrice;
        return {
            price: lowestPrice === 0 ? "" : `${getFromText(userLanguage)} ${roundNumber(lowestPrice)} kr`,
            discountPrice: lowestPrice === 0 ? "" : `${getFromText(userLanguage)} ${swedishPriceText(discountPrice)}`,
            isFree
        };
    } else {
        const addonPrice = !!isSingleMod ? getSelectedModificationAddonPrice(isSingleMod) : 0;
        const price = productBasePrice + addonPrice;
        const discountPrice = calculateFixedDiscountPrice(rateOrAmount, price);
        const isFree = discountPrice === 0 || price <= discountPrice;
        return {
            price: `${roundNumber(price)} kr`,
            discountPrice: swedishPriceText(discountPrice),
            isFree
        };
    }
};

export const getSelectedModificationAddonPrice = selectedModification => {
    if (!selectedModification) return 0;
    const sizeAddonPrice = selectedModification.sizes ? selectedModification.sizes.addonPrice : 0;
    const flavourAddonPrice = selectedModification.flavours ? selectedModification.flavours.addonPrice : 0;
    return sizeAddonPrice + flavourAddonPrice;
};

export const mathSign = price => {
    if (!price || price === 0 || price < 0) return "";
    return "+";
};

export const getSelectedModificationAddonPriceFromBackend = selectedModification => {
    if (!selectedModification) return 0;
    const sizeAddonPrice =
        selectedModification.sizes && selectedModification.sizes.length ? selectedModification.sizes[0].addonPrice : 0;
    const flavourAddonPrice =
        selectedModification.flavours && selectedModification.flavours.length
            ? selectedModification.flavours[0].addonPrice
            : 0;
    return sizeAddonPrice + flavourAddonPrice;
};

/**TEST SUITE */
export const calculateDiscountRate = (discount, cartTotalPrice) => {
    if (!discount) return null;
    else if (discount.amount > 0) {
        return Math.min(discount.amount / cartTotalPrice, 1);
    } else if (discount.rate > 0) {
        return discount.rate / 100.0;
    } else return null;
};

export const calculateFixedDiscountPrice = (rateOrDiscount, productPrice) => {
    if (!rateOrDiscount) {
        return productPrice;
    }

    if (rateOrDiscount.rate) {
        const discountRate = rateOrDiscount.rate / 100.0;
        const discountValue = roundNumber(discountRate * productPrice, 2);
        return productPrice - discountValue;
    } else {
        const discountedPrice = roundNumber(productPrice - rateOrDiscount.amount, 2);
        return discountedPrice < 0 ? 0 : discountedPrice;
    }
};

export const calculateAmountDiscountPrice = (amount, productPrice) => {
    return roundNumber(productPrice - amount, 2);
};

export const getCartTotalPrice = cartProducts => {
    return cartProducts ? cartProducts.reduce((tot, { orderProduct }) => tot + orderProduct.totalPrice, 0) : 0;
};

export const getCartTotalNetPrice = cartProducts => {
    return cartProducts ? cartProducts.reduce((tot, { orderProduct }) => tot + orderProduct.totalNetPrice, 0) : 0;
};

export const getOrderVatRates = order => {
    return order.orderProducts.reduce((tot, product) => {
        if (tot[product.vatRate]) {
            tot[product.vatRate] += calculateVatAmount(product.totalPrice, product.vatRate);
        } else {
            tot[product.vatRate] = calculateVatAmount(product.totalPrice, product.vatRate);
        }
        return tot;
    }, {});
};

/** Includes weight for price calculation  */
export const getCartTotalOriginalPrice = cartProducts => {
    return cartProducts
        ? cartProducts.reduce((total, cartProduct) => {
              const isWeightedProduct = isVariablePriceType(cartProduct.orderProduct?.priceType);
              const weightedProductAddonsPrice = isWeightedProduct
                  ? calculateTotalAddonPrices(cartProduct.orderProduct)
                  : 0;
              const quantityOrWeight = isWeightedProduct
                  ? cartProduct.orderProduct.weight
                  : cartProduct.orderProduct.quantity;
              const cartProductTotal =
                  (getCartProductOrgUnitPrice(cartProduct) - weightedProductAddonsPrice) * quantityOrWeight;
              total += cartProductTotal + weightedProductAddonsPrice;
              return total;
          }, 0)
        : 0;
};

/** TEST SUITE */
/** now includes weight for calculation with amount discounts */
export const getDiscountedCartTotalOriginalPrice = orderWindow => {
    const { cartProducts, discount, discountedProductIds } = orderWindow;
    if (discount && discount.amount > 0) {
        return cartProducts
            ? cartProducts.reduce((tot, cartProduct) => {
                  const { orderProduct, id } = cartProduct;
                  const addToTotal = discount && discountedProductIds && discountedProductIds.includes(id);
                  const isWeightedProduct = isVariablePriceType(orderProduct?.priceType);
                  const quantityOrWeight = isWeightedProduct ? orderProduct.weight : orderProduct.quantity;
                  const sumToAdd = addToTotal ? getCartProductOrgUnitPrice(cartProduct) * quantityOrWeight : 0;
                  return tot + sumToAdd;
              }, 0)
            : 0;
    } else {
        return null;
    }
};

/** TEST SUITE */
export const getDiscountedCartTotalAdjustedQuantities = (orderWindow, cartIdsAndQuantities) => {
    const { cartProducts, discount, discountedProductIds } = orderWindow;
    if (discount && discount.amount > 0) {
        return cartProducts.reduce((total, cartProduct) => {
            const { orderProduct, id } = cartProduct;
            const addToTotal = discount && discountedProductIds && discountedProductIds.includes(id);
            if (addToTotal) {
                let getQuantity = orderProduct.quantity;
                if (cartIdsAndQuantities) {
                    const checkQuantity = cartIdsAndQuantities.find(cart => cart.cartId === cartProduct.id);
                    if (checkQuantity) {
                        getQuantity = checkQuantity.quantity;
                    }
                }
                const isWeightedProduct = isVariablePriceType(orderProduct.priceType);
                let weightedProductAddons = 0;
                if (isWeightedProduct) {
                    getQuantity = orderProduct.weight;
                    weightedProductAddons = calculateTotalAddonPrices(orderProduct) ?? 0;
                }
                const sumToAdd = (getCartProductOrgUnitPrice(cartProduct) - weightedProductAddons) * getQuantity;
                total += sumToAdd + weightedProductAddons;
            }
            return total;
        }, 0);
    } else {
        return null;
    }
};

/** TEST SUITE */
export const getDiscountedCartTotalFromCartProducts = (
    cartProducts,
    discount,
    discountedProductIds,
    cartIdsAndQuantities
) => {
    if (discount && discount.amount > 0) {
        return cartProducts.reduce((total, cartProduct) => {
            const { orderProduct, id } = cartProduct;
            const addToTotal = discount && discountedProductIds && discountedProductIds.includes(id);
            if (addToTotal) {
                let getQuantity = orderProduct.quantity;
                if (cartIdsAndQuantities) {
                    const checkQuantity = cartIdsAndQuantities.find(cart => cart.cartId === cartProduct.id);
                    if (checkQuantity) {
                        getQuantity = checkQuantity.quantity;
                    }
                }
                const sumToAdd = getCartProductOrgUnitPrice(cartProduct) * getQuantity;
                total += sumToAdd;
            }
            return total;
        }, 0);
    } else {
        return null;
    }
};

/** Note: This is in the test suite */

// WARNING: Don't use this for new code, use instead:
// import { getVatAmount } from "PriceUtils";
/**
 * @deprecated import from "PriceUtils" (priceCalculationUtils.ts) instead
 */
export const calculateVatAmount = (total, rate) => {
    const vatRateDivider = 1 + rate / 100;
    return parseFloat((total - total / vatRateDivider).toFixed(2));
};

// WARNING: Don't use this for new code, use instead:
// import { roundToTwo } from "NumberUtils";
export const roundToTwo = num => {
    return +(Math.round(num + "e+2") + "e-2");
};

/**
 * Note: This is in the test Suite!!!
 */
// WARNING: Don't use this for new code, use instead:
// import { calculateMenuProductOrBundlePrice } from "PriceUtils";
export const calculateProductPrice = (
    menuProduct,
    quantity = 1,
    selectedModifications,
    selectedBundleItems,
    addons,
    updatedUnitPrice
) => {
    // debugger;
    const product = menuProduct.refProduct || menuProduct.refBundleProduct;
    const productPrice = isNumber(updatedUnitPrice)
        ? updatedUnitPrice
        : roundToTwo(getPrice(menuProduct, selectedModifications, selectedBundleItems, addons));
    const totalPrice = roundToTwo(productPrice * quantity);
    const vatAmount = getVatAmount(product.vatRate, totalPrice);
    const totalNetPrice = roundToTwo(totalPrice - vatAmount);

    return {
        unitPrice: productPrice,
        totalPrice,
        vatRate: product.vatRate,
        totalNetPrice
    };
};

/**
 * Recalculates totalPrice and totalNetPrice for a new unit price
 * @param {number} newUnitPrice - the new unit price
 * @param {object} cartProduct - the cart product to update
 * @return {object} containing unitPrice, totalPrice, totalNetPrice
 */
export const getUpdatedUnitPrice = (newUnitPrice, cartProduct) => {
    const { orderProduct, menuProduct, menuBundleProduct } = cartProduct;
    const menuProd = menuProduct ? menuProduct : menuBundleProduct;
    const refProd = menuProd.refProduct || menuProd.refBundleProduct;

    let quantityOrWeight = orderProduct.quantity;
    const isWeightProduct = isVariablePriceType(orderProduct.priceType);
    if (isWeightProduct) {
        quantityOrWeight = orderProduct.weight;
    }
    const totalPrice = roundToTwo(newUnitPrice * quantityOrWeight);
    const vatAmount = getVatAmount(refProd.vatRate, totalPrice);
    const totalNetPrice = roundToTwo(totalPrice - vatAmount);
    const orgUnitPrice = getCartProductOrgUnitPrice(cartProduct);

    const newPriceIsDiscounted = newUnitPrice < orgUnitPrice;
    if (newPriceIsDiscounted) {
        const orgTotalPrice = roundToTwo(orgUnitPrice * quantityOrWeight);
        const discountValue = orgTotalPrice - totalPrice;
        const discountRate = round(discountValue / orgTotalPrice, 5);

        const discount = {
            discountId: createSimpleHashId([DISCOUNT_TYPE.PRICE_ADJUST]),
            name: "Justerat pris",
            discountValue: discountValue,
            discountRate: discountRate,
            discountedFrom: orgTotalPrice,
            discountOrder: 1,
            quantityUsedForDiscount: orderProduct.quantity,
            discountType: DISCOUNT_TYPE.PRICE_ADJUST
        };

        return {
            combinedDiscounts: [discount],
            discountRate: discountRate,
            discountValue: discountValue,
            unitPrice: newUnitPrice,
            totalPrice,
            totalNetPrice
        };
    }

    return {
        unitPrice: newUnitPrice,
        totalPrice,
        totalNetPrice
    };
};
